export const setupBreadcrumbs = () => {
    const triggerLinks = document.querySelectorAll('[data-breadcrumb-labels]');
    triggerLinks.forEach((trigger) => {
        trigger.addEventListener('click', triggerHandler);
    });

    const breadcrumbs = getSessionStorage();
    createBreadcrumbs(breadcrumbs);
    appendBreadcrumbsLinks(breadcrumbs);

    const breadcrumbLast = document.querySelector('[data-breadcrumb-last]');

    if(!breadcrumbLast) {
        clearSessionStorage();
    }
};

const createBreadcrumbs = (breadcrumbs) => {
    const breadcrumbLast = document.querySelector('[data-breadcrumb-last]');
    if(breadcrumbLast) {
        const breadcrumbHolder = breadcrumbLast.parentElement;
        breadcrumbs.labels.forEach((label, index) => {
            const url = breadcrumbs.urls[index];

            if(!url || !label || window.location.toString().includes(url)) {
                return;
            }

            const separator =  document.createElement('span');
            separator.innerHTML = '&nbsp;>&nbsp;';

            const newCrumb = document.createElement('a');
            newCrumb.innerHTML = label;
            newCrumb.href = url;

            breadcrumbHolder.insertBefore(newCrumb, breadcrumbLast);
            breadcrumbHolder.insertBefore(separator, breadcrumbLast);
        });
    }
}

const appendBreadcrumbsLinks = (breadcrumbs) => {
    const breadcrumbAppends = document.querySelectorAll('[data-breadcrumb-append]');
    breadcrumbAppends.forEach((append, index) => {
        const existingUrls = append.dataset.breadcrumbUrls.split(';') || [];
        const existingLabels = append.dataset.breadcrumbLabels.split(';') || [];

        const urls = [... new Set(breadcrumbs.urls.concat(existingUrls))];
        const labels = [... new Set(breadcrumbs.labels.concat(existingLabels))];

        append.dataset.breadcrumbUrls = urls.join(';');
        append.dataset.breadcrumbLabels = labels.join(';');
    });
};

const triggerHandler = (e) => {
    e?.preventDefault();

    if(e.currentTarget.dataset.breadcrumbLabels && e.currentTarget.dataset.breadcrumbUrls) {
        clearSessionStorage();
        setSessionStorage(e.currentTarget.dataset.breadcrumbUrls, e.currentTarget.dataset.breadcrumbLabels);
    }

    window.location = e.currentTarget.href;
};

const setSessionStorage = (urls, labels) => {
    try{
        window.sessionStorage.setItem('breadcrumb-labels', labels);
        window.sessionStorage.setItem('breadcrumb-urls', urls);
    }
    catch(e) {
        console.error(e);
    }
};

const clearSessionStorage = () => {
    try{
        window.sessionStorage.removeItem('breadcrumb-labels');
        window.sessionStorage.removeItem('breadcrumb-urls');
    }
    catch(e) {
        console.error(e);
    }
};

const getSessionStorage = () => {
    try{
        return {
            labels: window.sessionStorage.getItem('breadcrumb-labels')?.split(';') || [],
            urls: window.sessionStorage.getItem('breadcrumb-urls')?.split(';') || []
        }
    }
    catch(e) {
        console.error(e);

        return {
            labels: [],
            urls: []
        };
    }
}
