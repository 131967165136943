import { setupBreadcrumbs } from "./modules/breadcrumbs";

let $ = window.jQuery;

$(function () {
    setupBreadcrumbs();

    $('body').addClass('page-loaded');

    $('[data-slick]').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    });

    // var openedOnce = false;

    // document.onmousemove = function (e) { 
    //     if (e.clientY < 20 && !openedOnce) {
    //         if ($('.news-overlay').length) {
    //             openedOnce = true;
    //             $('.news-overlay').addClass('takeover').slideDown(300);
    //             $('.news-overlay-bg').fadeIn(300);
    //         }
    //     }
    // }

    $('[data-e1]').click(function (e) {
        e.preventDefault();
        var em = $(this).attr('data-e1') + '@' + $(this).attr('data-e2') + '.com';
        window.location = 'mailto:' + em;
    });

    if ($('.cxl-section-hero').length) {
        setInterval(function () {
            var current = $('.cxl-phrases .cxl-p.active');
            current.removeClass('active');

           if (current.next().length) {
                current.next().addClass('active');
            } else {
                $('.cxl-phrases .cxl-p:nth-child(1)').addClass('active');
            }
        }, 4000);
    }

    if ($('.half-circles').length) {
        setInterval(function () {
            $('.half-circles.out').each(function () {
                if ($(this).inview()) {
                    $(this).addClass('in').removeClass('out');
                }
            });
        }, 100);
    }

    // contact form validate
    $("#contactForm").validate({
        rules: {
            zip: {
                minlength: 5,
                maxlength: 5,
                digits: true

            }
        },
        errorPlacement: function (error, element) {
            if (element.attr("name") != 'inventory-count' && element.attr("name") != 'current-customer') {
                error.insertAfter(element);
            }

            if ((!$('input[name="inventory-count"]:checked').length || !$('input[name="current-customer"]:checked').length) && $('div.automobile-dealers').is(':visible')) {
                $('.automobile-dealers .errors').show();
                $('input[name="inventory-count"]:first').trigger('blur')
            } else {
                $('.automobile-dealers .errors').hide();
            }
        }
    });

    if ($('#contactForm').length) {
        $('.automobile-dealers').change(function () {
            var count = $('.automobile-dealers:checked').length,
                div = $('div.automobile-dealers');

            if (count) {
                div.show();
                $('input[name="inventory-count"],input[name="current-customer"]').addClass('required');
            } else {
                div.hide();
                $('input[name="inventory-count"],input[name="current-customer"]').removeClass('required');
            }
        }).trigger('change');

        $('#contactForm').on('submit', function (e) {
            if ($(this).valid()) {
                $.atdLoadingAdd();
            }
        });
    }

    $('[data-trigger="hover"]').mouseover(function () {
        $(this).trigger('click');
    });


    // search form

    $('#search-form').submit(function (e) {
        if ($('#s').val() === '') {
            e.preventDefault();
        }
    });

    // placeholders

    $('input, textarea').placeholder();

    // alert toggle

    setTimeout(function () {
        $('.alert:not(.dont-hide)').slideToggle(400);
    }, 4000);


    // dealer-solutions

    $('.navigation > li').click(function () {
        var navigation = $(this).parent();
        navigation.children('li').removeClass('active');
        $(this).addClass('active');

        var slide = $(this).index();

        navigation.next().children('li').removeClass('active');
        navigation.next().children('li').eq(slide).addClass('active');
    });

    // homepage back-to-top

    $('.back-to-top > a').click(function () {
        $('html,body').animate({ scrollTop: 0 }, 600);
        return false;
    });

    // homepage slidetoggle

    $('.read-more-show').click(function (e) {
        e.preventDefault();
        $(this).css('visibility', 'hidden');
        $('.hidden-section').slideToggle('slow');
    });

    $('.read-more-hide').click(function (e) {
        e.preventDefault();
        $('.hidden-section').slideToggle('slow', function () {
            $('.read-more-show').css('visibility', 'visible');
        });
    });


    // Infographic toggle on ICO page
    $('.infographic-toggle').click(function (e) {
        e.preventDefault();
        $('.infographic').toggleClass('open');
    });
    $('.infographic-footer').click(function (e) {
        e.preventDefault();
        $('.infographic').toggleClass('open');
    });

    // solutions post-type slideshow


    // if ($('.thumbs-box > ul > li').size() > 1) { // if slideshow has more than one slide, initialize slideshow
    //     var changeSlide = setInterval(function () {
    //         switchImage();
    //     }, 6000);
    // }

    // $('.thumbs-box > ul > li').click(function () {

    //     clearInterval(changeSlide);
    //     switchImage(0, $(this));

    // });

    // $('.thumbs-box > ul > li:last-child').addClass('last-child');

    // function switchImage(clicked, clickedThumb) {
    //     var current = $('.thumbs-box > ul > li.active'),
    //         next,
    //         slide,
    //         last = $('.thumbs-box > ul > li:last-child');

    //     if (last.index() === 1) {
    //         return;
    //     }

    //     if (clicked !== 0) {
    //         if (current.index() === last.index()) {
    //             next = $('.thumbs-box > ul > li:first-child');
    //         } else {
    //             next = current.next();
    //         }
    //     } else {
    //         next = clickedThumb;
    //     }

    //     slide = next.index();

    //     current.removeClass('active');
    //     next.addClass('active');

    //     $('.image-box > ul > li').removeClass('active');
    //     $('.image-box > ul > li').eq(slide).addClass('active');

    // }
    
    // ico post-type slideshow


    if ($('.thumbs-box > ul > li').size() > 1) { // if slideshow has more than one slide, initialize slideshow
        var changeSlide = setInterval(function () {
            switchImage();
        }, 6000);
    }

    $('.thumbs-box > ul > li').click(function () {

        clearInterval(changeSlide);
        switchImage(0, $(this));

    });

    $('.thumbs-box > ul > li:last-child').addClass('last-child');

    function switchImage(clicked, clickedThumb) {
        var current = $('.thumbs-box > ul > li.active'),
            next,
            slide,
            last = $('.thumbs-box > ul > li:last-child');

        if (last.index() === 1) {
            return;
        }

        if (clicked !== 0) {
            if (current.index() === last.index()) {
                next = $('.thumbs-box > ul > li:first-child');
            } else {
                next = current.next();
            }
        } else {
            next = clickedThumb;
        }

        slide = next.index();

        current.removeClass('active');
        next.addClass('active');

        $('.image-box > ul > li').removeClass('active');
        $('.image-box > ul > li').eq(slide).addClass('active');

    }

    // dealer solutions slide show


    // if ($('.navigation > li').size() > 1) { // if slideshow has more than one slide, initialize slideshow
    //     var solutionSlide = setInterval(function() {
    //             solutionImage();
    //         }, 4000);
    // }

    // $('.navigation > li').click(function() {

    //     clearInterval(solutionSlide);
    //     solutionImage(0, $(this));

    // });

    // $('.thumbs-box > ul > li:last-child').addClass('last-child');

    // function solutionImage(clicked, clickedThumb) {
    //     var current = $('.navigation > li.active'),
    //     next,
    //     slide,
    //     last = $('.navigation > li:last-child');

    //     if (last.index() === 1) {
    //         return;
    //     }

    //     if (clicked !== 0) {
    //         if (current.index() === last.index()) {
    //             next = $('.navigation > li:first-child');
    //         } else {
    //             next = current.next();
    //         }
    //     } else {
    //         next = clickedThumb;
    //     }

    //     slide = next.index();

    //     current.removeClass('active');
    //     next.addClass('active');

    //     $('.body > li.boxes').removeClass('active');
    //     $('.body > li.boxes').eq(slide).addClass('active');

    // }

 
    // homepage slideshow

    var check = false;

    var homeSlide = setInterval(function () { // initialize slideshow after timeout
        homeSwitch();
    }, 7000);

    $('.hero-nav > li').click(function (e) {

        e.preventDefault();
        clearInterval(homeSlide);

        if (check === false && $('.hero-slideshow-slide.current').index() !== $(this).index()) {  // check for: callback completed, not current slide
            check = true;
            homeSwitch(0, $(this).index());

        }
    });

    function homeSwitch(clicked, clickedThumb) {
        var current = $('.hero-slideshow-slide.current'),
            next,
            last = $('.hero-slideshow-slide:last-child');

        if (clicked !== 0) {
            if (current.index() === last.index()) {
                next = $('.hero-slideshow-slide:first-child');
            } else {
                next = current.next();
            }
        } else {
            next = $('.hero-slideshow-slide').eq(clickedThumb);
        }

        $('.hero-nav > li > a.active').removeClass('active');
        $('.hero-nav > li > a').eq(next.index()).addClass('active');

        current.removeClass('current').fadeOut(1000);

        next.addClass('current').fadeIn(1000, function () {
            check = false;
        });

    }

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip"]').on('shown.bs.tooltip', function (e) {
        var tooltip = $(this).next('.tooltip');
        if ($(this).hasClass('touched')) {
            $(this).removeClass('touched');
            if (!tooltip.find('.close').length) {
                tooltip.append('<a href="#" class="close">X</a>');
                tooltip.find('.close').on('click', function (e) {
                    e.preventDefault();
                    tooltip.toggle('click');
                });
            }
        }
    });

    $('[data-toggle="tooltip"]').on('touchstart', function () {
        $(this).addClass('touched');
    });


    $('[data-googlepdf]').click(function () {
        //_gaq.push(['_trackEvent', 'PDF', 'Viewed', $(this).data('googlepdf')]);
    });

    $('[data-googlevideo]').click(function () {
        //_gaq.push(['_trackEvent', 'Videos', 'Played', $(this).data('googlevideo')]);
    });


    // modal
    var videoModals = $('a[href*=".mp4"]');
    if (videoModals.length) {
        videoModals.atdVideoModal({
            'flowplayer': '/wp-content/themes/iron-man/swf/flowplayer.swf'
        });
    }


    $('a[data-vimeo-id]').atdVideoModal({
        'flowplayer': '/wp-content/themes/iron-man/swf/flowplayer.swf'
    });

    //Super menu

    /*
    $('.solutions-super-menu-trigger, .solutions-super-menu').hover(function() {
        $('.solutions-super-menu').addClass('active');
    }, function() {
        $('.solutions-super-menu').removeClass('active');
    });
 
    $('.solutions-super-menu-trigger a').on("tap",function(e) {
        e.preventDefault();
    });
 
    $('.vehicle-values-super-menu-trigger, .vehicle-values-super-menu').hover(function() {
        $('.vehicle-values-super-menu').addClass('active');
    }, function() {
        $('.vehicle-values-super-menu').removeClass('active');
    });
 
    $('.vehicle-values-super-menu-trigger a').on("tap",function(e) {
        e.preventDefault();
    });
 
    /*$('body').touchstart(function(e) {
        $(".vehicle-values-super-menu").removeClass('active');
    });*/

    $('.vehicle-values-super-menu-trigger a').on("tap", function (e) {
        e.preventDefault();
    });

    $('.solutions-super-menu-trigger a').on("tap", function (e) {
        e.preventDefault();
    });

    $('.why-kbb-super-menu-trigger a').on("tap", function (e) {
        e.preventDefault();
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() > 0) {
            $('.vehicle-values-super-menu').fadeOut();
        }
        if ($(this).scrollTop() > 0) {
            $('.solutions-super-menu').fadeOut();
        }
        if ($(this).scrollTop() > 0) {
            $('.kbb-super-menu').fadeOut();
        }
    });

    // Mobile touch nav
    $(function () {
        $('.vehicle-values-super-menu-trigger').doubleTapToGo();
        $('.solutions-super-menu-trigger').doubleTapToGo();
        $('.why-kbb-super-menu-trigger').doubleTapToGo();
    });

    //OEM sub-menu
    if ($(window).width() > 768) {
        if ($('.ad-opportunities-nav').hasClass('active')) {
            $('.insights-research-nav').hover(function () {
                $('.ad-opportunities-nav.active .sub-menu').hide();
            }, function () {
                $('.ad-opportunities-nav .sub-menu').show();
            });
        }
    }

    if ($(window).width() > 768) {
        if ($('#fixed-navigation').length > 0) {
            var navOffset = $('#fixed-navigation').offset().top;
            var bodyHeight = $('#fixed-navigation-body').height();
            var hiddenHeight = navOffset + bodyHeight - 100;

            var section1 = $('#market').offset().top - 45;
            var section2 = $('#enhance').offset().top - 45;
            var section3 = $('#tools').offset().top - 45;

            checkScroll();

            $(window).scroll(function () {
                checkScroll();
            });

            $('#fixed-navigation a').click(function () {
                var target = $(this).attr('href');
                var targetOffset = $(target).offset().top - 40;

                $('html, body').animate({
                    scrollTop: targetOffset + 'px'
                }, 1000);

                return false;
            });
        }
    }
 
    // solutions ad carousel

    $('[atd-slideshow]').on('click', '[atd-slideshow-thumb]', function () {

        var slideshow = $(this).parents('[atd-slideshow]');

        var thumbs = slideshow.find('[atd-slideshow-thumbs]');

        var thumb = slideshow.find('[atd-slideshow-thumb]');

        var image = slideshow.find('[atd-slideshow-image]');

        var index = $(this).data('index');

        var currentActive = slideshow.find('li.active');

        var newActive = slideshow.find('[data-index="' + index + '"]');

        currentActive.removeClass('active');

        newActive.addClass('active');

        var newImgUrl = newActive.children('a').data('download-url'),
            downloadLink = $('[data-dynamic-link]');

        downloadLink.attr('href', newImgUrl);

        thumb.attr('atd-slideshow-thumb', '').parent().removeClass('active');
        $(this).attr('atd-slideshow-thumb', 'active').parent().addClass('active');

        atdSlideToActive(thumbs);

        return false;
    });

    $('[atd-slideshow]').on('click', '[atd-slideshow-control]', function () {

        var slideshow = $(this).parents('[atd-slideshow]');

        var thumb = slideshow.find('[atd-slideshow-thumb].active');

        var dir = $(this).attr('atd-slideshow-control');

        if (dir === 'next') {
            thumb.next('[atd-slideshow-thumb]').trigger('click');
        } else {
            thumb.prev('[atd-slideshow-thumb]').trigger('click');
        }

        return false;
    });

    $('.atd-slideshow .vdp-slides li a').click(function (e) {
        e.preventDefault();
    });

    // sortable tables
    $('[data-sort="table"]').each(function () {
        new Tablesort($(this).get(0));
    });


    function atdSlideToActive(thumbs) {

        if ($('.atd-slideshow .vdp-slides li').length > 4) {

            var active = thumbs.find('[atd-slideshow-thumb="active"]');

            var offset = -Math.floor(active.position().left) + 4;

            thumbs.css('margin-left', offset + 'px');
        }
    }

    function checkScroll() {
        var windowScroll = $(window).scrollTop();

        if ($(window).width() > 768) {
            if (windowScroll >= hiddenHeight) {
                $('#fixed-navigation').removeClass('fixed');
            } else if (windowScroll >= navOffset) {
                $('#fixed-navigation').addClass('fixed');
            } else {
                $('#fixed-navigation').removeClass('fixed');
            }
        }

        if (windowScroll >= section1 && windowScroll < section2) {
            $('#fixed-navigation .first-child').addClass('active');
        } else {
            $('#fixed-navigation .first-child').removeClass('active');
        }

        if (windowScroll >= section2 && windowScroll < section3) {
            $('#fixed-navigation .middle-child').addClass('active');
        } else {
            $('#fixed-navigation .middle-child').removeClass('active');
        }

        if (windowScroll >= section3) {
            $('#fixed-navigation .last-child').addClass('active');
        } else {
            $('#fixed-navigation .last-child').removeClass('active');
        }
    }


    //Perfect Vehicle
    $('.goals-dropdown .trigger').click(function (e) {
        e.stopPropagation();
        $('.dropdown').slideToggle();
    });

    $(document).click(function () {
        $(".dropdown").fadeOut();
    });

    $('.point-info .point').mouseover(function () {
        $('.point-details').not($(this).next()).removeClass('active');
        $(this).next().addClass('active');
    });

    $('.point-info .point').click(function (e) {
        e.preventDefault();
        $('.point-details').not($(this).next()).removeClass('active');
        $(this).next().toggleClass('active');
    });

    $('.back-to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });

    $('.point-details .close').click(function (e) {
        e.preventDefault();
        $('.point-details').removeClass('active');
    });

    //Tracking URLS for header
    $('.navbar-nav li a').each(function () {
        var headerUrl = $(this).attr("href");
        $(this).attr("href", headerUrl);
    });

    $('.footer-nav li a').each(function () {
        var footerUrl = $(this).attr("href");
        $(this).attr("href", footerUrl);
    });

    $('#menu-home-menu li a').each(function () {
        var menuUrl = $(this).attr("href");
        $(this).attr("href", menuUrl);
    });


    //Mobile OEM sub-menu accordion
    if ($(window).width() < 768) {
        var submenus = $('.oem-page-nav .nav-item .sub-menu');
        $('.oem-page-nav .oem-nav > .nav-item > a').click(function (e) {
            e.preventDefault();
            submenus.slideUp();
            if ($(this).next().is(':hidden')) {
                $(this).next().slideDown();
            }
        });
    }

    $('.faqItem .accordion__heading').click(function () {
        var target = $(this).parents('.faqItem');
        target.toggleClass('active');
        target.find('.accordion__content').slideToggle(400);
    })
});

// Glossarize
$(document).ready(function () {

    $('.brand-bar .brand-btn').click(function (e) {
        e.preventDefault();
        $(this).closest('.brand-bar').toggleClass('active').find('.expanded-bar').slideToggle(400);
    });

    $('.container').glossarizer({
        sourceURL: $('body').data('glossarize'),
        callback: function () {
            $('.glossarizer_replaced').tooltip();
            $('.glossarizer_replaced').on('shown.bs.tooltip', function (e) {
                var tooltip = $(this).next('.tooltip');
                if ($(this).hasClass('touched')) {
                    $(this).removeClass('touched');
                    if (!tooltip.find('.close').length) {
                        tooltip.append('<a href="#" class="close">X</a>');
                        tooltip.find('.close').on('click', function (e) {
                            e.preventDefault();
                            tooltip.toggle('click');
                        });
                    }
                }
            });

            $('.glossarizer_replaced').on('touchstart', function () {
                $(this).addClass('touched');
            });
        }
    });


});

$(window).load(triggerHash);

window.onhashchange = triggerHash;

function triggerHash() {
    $('[data-trigger="hash"]').each(function () {
        var url = window.location.toString();
        var url_split = url.split('#');

        if (url_split[1]) {
            var params = url_split[1].split('?');
            var param = params[0];

            if (params[1]) {
                var trigger = params[1].replace('trigger=', '');

                if (trigger === $(this).attr('id')) {
                    $(this).trigger('click');
                }
            }

            if (param === $(this).attr('id')) {
                $(this).trigger('click');
                var offset = $(this).offset().top;

                $(window).scrollTop(offset);
            }
        }
    });

}

$(document).ready(function () {
    $('.news-close, .news-click-close').click(function (e) {
        e.preventDefault();
        $(this).parent().remove();
        $('.news-overlay-bg').remove();
    });

    $('.news-overlay-bg').click(function (e) {
        e.preventDefault();
        $('.news-overlay').slideUp(300);
        $(this).fadeOut(300);
    });

    if ($('.industry-slideshow').length > 0) {
        var slideshow = $('.industry-slideshow');

        var autoSlide = setInterval(function () {
            var current = slideshow.find('.slide.active');
            var next = current.next();

            if (next.length <= 0) {
                next = slideshow.find('.slide:nth-child(1)');
            }

            var index = next.data('index');

            slideshow.find('.slide-pagination a').removeClass('active');
            slideshow.find('.slide-pagination a:nth-child(' + index + ')').addClass('active');

            current.removeClass('active');
            next.addClass('active');
        }, 6000);

        slideshow.find('.slide-pagination a').click(function (e) {
            e.preventDefault();
            var index = $(this).data('index');
            var current = slideshow.find('.slide.active');
            var next = slideshow.find('.slide:nth-child(' + index + ')');

            slideshow.find('.slide-pagination a').removeClass('active');
            $(this).addClass('active');

            current.removeClass('active');
            next.addClass('active');

            clearInterval(autoSlide);
        });
    }

    //More Industries dropdown on homepage
    $select = $('#more-industries');
    $select.change(function () {
        window.location = $select.val();
    });

    //Select Your Industry Mega Menu

    /*$('.menu-item-has-children').hoverIntent({
        over: function () {
            $(this).children('.sub-menu, .sub-menu-old').show();
        },
        out: function () {
            $(this).children('.sub-menu, .sub-menu-old').hide();
        },
        timeout: 500
    });*/

    /* Digital Prodcut Catalog Tab Show/Hide*/

    var $dpcNavActive,
        $dpcTabActive,
        $dpcNav = $('#js-dcp-nav'),
        $dpcLink = $('#js-dcp-nav a');

    var $dpcTableHead,
        $dpcTableBody,
        tableHeight,
        tableBottom;


    $dpcLink.click(function (e) {
        e.preventDefault();
        //get index of clicked link
        var index = $(this).data('index');

        //get the current active link and tab
        $dpcNavActive = $('#js-dcp-nav .active');
        $dpcTabActive = $('#js-digital-product-catalog .tab-content.active');

        //if clicked nav link is not currently active
        if ($dpcNavActive.data('index') !== index) {
            $dpcNavActive.removeClass('active');
            $dpcTabActive.fadeOut(200).removeClass('active');

            $(this).addClass('active');
            $('#js-digital-product-catalog .tab-content[data-index=' + index + ']').fadeIn(300).addClass('active');
            $(window).trigger('resize');

            $dpcTableHead = $('#js-table-head-fixed');
            $dpcTableBody = $('#tablesaw-1 tbody');
            tableHeight = $dpcTableHead.offset().top;
            tableBottom = tableHeight + $dpcTableBody.outerHeight();
        }
    });

    /* Digital Product Catalog Sub Nav Smooth Scroll*/
    var hashTagActive = "";
    $('[data-scroll="true"]').click(function (e) {
        e.preventDefault();
        var currentActive = $('[data-scroll="true"].active').removeClass('active');
        var alwaysScroll = $(this).attr('data-scroll-always');
        $(this).addClass('active');
        if (hashTagActive !== this.hash || alwaysScroll) {
            e.preventDefault();
            //calculate destination place
            var dest = 0;
            if ($(this.hash).offset().top > $(document).height() - $(window).height()) {
                dest = $(document).height() - $(window).height();
            } else {
                dest = $(this.hash).offset().top;
            }
            //go to destination

            $('html,body').animate({
                scrollTop: dest - 200
            }, 700);

            hashTagActive = this.hash;
        }
    });


    /* Digital Product Catalog sticky nav and subnav*/
    if ($(window).width() > 768) {
        var $dpcNavFixed = $('#js-dcp-nav-fixed');
        var $dpcSubNavFixed = $('#js-sub-nav-fixed');

        if ($('#js-dcp-nav-fixed').length > 0) {
            var navOffset = $('#js-dcp-nav-fixed').offset().top;
            $dpcTabActive = $('.tab-content.active');
            var bodyHeight = $dpcTabActive.height();
            var hiddenHeight = navOffset + bodyHeight - 100;

            $(window).scroll(function () {
                var windowScroll = $(window).scrollTop();
                $dpcTabActive = $('.tab-content.active');

                if ($dpcNavFixed.height() > 137) {
                    $dpcSubNavFixed.css('top', $dpcNavFixed.height());
                }


                if (navOffset <= windowScroll) {
                    $dpcNavFixed.addClass('fixed');
                    $dpcSubNavFixed.addClass('fixed');

                    if ($dpcTabActive.data('index') !== 0) {
                        $dpcTabActive.addClass('offset-small');
                    } else {
                        $dpcTabActive.addClass('offset');
                    }
                } else {
                    $dpcNavFixed.removeClass('fixed');
                    $dpcSubNavFixed.removeClass('fixed');
                    $dpcTabActive.removeClass('offset-small');

                    if ($dpcTabActive.data('index') !== 0) {
                        $dpcTabActive.removeClass('offset-small');
                    } else {
                        $dpcTabActive.removeClass('offset');
                    }
                }
            });
        }


        /* DPC update subnav link states on scroll*/
        if ($('#js-sub-nav-fixed').length > 0) {
            var stickyNavHeight = 400;
            var overviewHeight = $('#strategy-overview-section').offset().top - stickyNavHeight;
            var landscapeHeight = $('#competitive-landscape-section').offset().top - stickyNavHeight;
            var marketectureHeight = $('#marketecture-section').offset().top - stickyNavHeight;
            var metricsHeight = $('#metrics-section').offset().top - stickyNavHeight;

            var $overviewLink = $('#overview-link');
            var $landscapeLink = $('#landscape-link');
            var $marketectureLink = $('#marketecture-link');
            var $metricsLink = $('#metrics-link');

            $(window).scroll(function () {
                var $activeLink = $('#js-sub-nav-fixed li .active');
                var windowScroll = $(window).scrollTop();
                if (overviewHeight <= windowScroll && windowScroll < landscapeHeight) {
                    $activeLink.removeClass('active');
                    $overviewLink.addClass('active');
                } else if (landscapeHeight <= windowScroll && windowScroll < marketectureHeight) {
                    $activeLink.removeClass('active');
                    $landscapeLink.addClass('active');
                } else if (marketectureHeight <= windowScroll && windowScroll < metricsHeight) {
                    $activeLink.removeClass('active');
                    $marketectureLink.addClass('active');
                } else if (metricsHeight <= windowScroll) {
                    $activeLink.removeClass('active');
                    $metricsLink.addClass('active');
                }
            });

        }
    }

    $(window).scroll(function () {

        var windowScroll = $(window).scrollTop();
        if (typeof $dpcTableHead !== "undefined") {

            if (windowScroll > tableHeight && windowScroll < tableBottom) {
                $dpcTableHead.addClass('fixed');
            } else {
                $dpcTableHead.removeClass('fixed');
            }
        }
    });

    $('#js-tablesaw-prev, #js-tablesaw-prev-2').click(function (e) {
        e.preventDefault();
        //$(this).siblings(".tablesaw-bar").find('.tablesaw-nav-btn.left').trigger('click');
        $('#tablesaw-1, #tablesaw-2').trigger('tablesawprev.swipetoggle');
    });

    $('#js-tablesaw-next, #js-tablesaw-next-2').click(function (e) {
        e.preventDefault();
        $('#tablesaw-1, #tablesaw-2').trigger('tablesawnext.swipetoggle');
    });

    $('.tablesaw-1 .tablesaw-nav-btn.right').click(function (e) {
        e.preventDefault();
        $('#tablesaw-2').trigger('tablesawnext.swipetoggle');
    });

    $('.tablesaw-1 .tablesaw-nav-btn.left').click(function (e) {
        e.preventDefault();
        $('#tablesaw-2').trigger('tablesawprev.swipetoggle');
    });

    $('.tablesaw-2 .tablesaw-nav-btn.right').click(function (e) {
        e.preventDefault();
        $('#tablesaw-1').trigger('tablesawnext.swipetoggle');
    });

    $('.tablesaw-2 .tablesaw-nav-btn.left').click(function (e) {
        e.preventDefault();
        $('#tablesaw-1').trigger('tablesawprev.swipetoggle');
    });


    //Digital Product Catalog Special print

    $('#print-btn').click(function (e) {
        var inputs = $('.print input');
        inputs.each(function () {
            var id = $(this).attr('id');
            var pattern = /-print/g;
            id = '#' + id.replace(pattern, '');

            if ($(id).hasClass('no-print')) {
                $(id).removeClass('no-print');
            }

            if (!$(this).is(':checked')) {
                $(id).addClass('no-print');
            }
        });

        window.print();
    });


    //Digital Product Catalog Special print

    $('#print-btn').click(function (e) {
        var inputs = $('.print input');
        inputs.each(function () {
            var id = $(this).attr('id');
            var pattern = /-print/g;
            id = '#' + id.replace(pattern, '');

            if ($(id).hasClass('no-print')) {
                $(id).removeClass('no-print');
            }

            if (!$(this).is(':checked')) {
                $(id).addClass('no-print');
            }
        });

        window.print();
    });


    /*Valuation Evolution Map pins*/

    var pins = $('#js-map-pins .pin');
    for (var i = 0; i < pins.length; i++) {
        var x = $(pins[i]).data('x');
        var y = $(pins[i]).data('y');
        $(pins[i]).css({ "left": x + "px", "top": y + "px" });
    }

    $('[data-feature]').each(function () {
        var width = parseInt($(this).data('width'), 10),
            height = parseInt($(this).data('height'), 10),
            x = parseInt($(this).data('x'), 10),
            y = parseInt($(this).data('y'), 10),
            title = $(this).data('title'),
            src = $(this).data('src'),
            tooltipPlacement = $(this).data('placement') || 'top';

        var $highlight = $('[data-highlight]'),
            $overlay = $('[data-overlay]'),
            $container = $('.placeholder-container'),
            $placeholder = $('.placeholder'),
            $img = $('[data-img]');

        $(this).click(function (e) {
            e.preventDefault();

            if ($(window).width() < 980) {
                var dest = 0;
                if ($container.offset().top > $(document).height() - $(window).height()) {
                    dest = $(document).height() - $(window).height();
                } else {
                    dest = $container.offset().top;
                }
                //go to destination
                $('html,body').animate({
                    scrollTop: dest - 20
                }, 700);
            }

            $img.attr("src", src);

            var tempImg = new Image();

            $(tempImg).on('load', function () {
                var originalHeight = this.height;
                var originalWidth = this.width;

                var currentWidth = $img.innerWidth();
                var currentHeight = $img.innerHeight();

                var widthRatio = currentWidth / originalWidth;
                var heightRatio = currentHeight / originalHeight;

                $highlight.tooltip('destroy');

                $overlay.addClass('active');
                $highlight.css({
                    'width': width * widthRatio,
                    'height': height * heightRatio,
                    'left': x * widthRatio,
                    'top': y * heightRatio,
                    'background-position': (-x) + "px" + " " + (-y) + "px",
                    'background-image': 'url("' + src + '")',
                    //'background-size' : widthRatio * 100 + '%'
                }).attr({
                    'data-toggle': 'tooltip',
                    'title': title,
                    'data-original-title': title,
                    'data-placement': tooltipPlacement
                });

                $highlight.tooltip({ 'placement': tooltipPlacement }).tooltip('show');
                $container.animate({
                    scrollTop: heightRatio * ($highlight.offset().top - $container.offset().top - 100 + $container.scrollTop())
                }, 500);
            });

            tempImg.src = src;
        });
    });

    // Search expand for main nav
    $('.btn-search').click(function () {
        $('#search-form').fadeToggle();
    });
    $('.search-close').click(function () {
        $('#search-form').fadeToggle();
    });

    var stickyNav = function () {
        var scrollTop = $(window).scrollTop();
        if (scrollTop > 106) {
            $('.navbar-800bb-header, .okta-nav, .brand-bar').addClass('sticky');
            $('body.single, .page').addClass('padded');
        } else {
            $('.navbar-800bb-header, .okta-nav, .brand-bar').removeClass('sticky');
            $('body.single, .page').removeClass('padded');
        }
    };
    stickyNav();

    $(window).scroll(function () {
        stickyNav();
    });
});

$(document).ready(function () {
    if ($('#100for100-success').length) {
        //_gaq.push(['_trackEvent', '100 for 100', 'Form', 'Form submitted']);
    }

    $('.connections-app-phone').click(function () {
        $('.connections-app-phone').removeClass('active');
        $(this).addClass('active');
    });
});

// FlexSlider
$(window).on('load', function () {
    $('.home-slider').flexslider({
        directionNav: false,
    });

    $('.testimonial-slider').flexslider({
        // directionNav: false,
        animation: "slide",
        slideshow: false,
        animationLoop: true,

        after: function () {
            $videoSlide.not('.flex-active-slide').removeClass('video-open');
            $videoSlide.not('.flex-active-slide').find(".video-modal").remove();
        }
    })

    var $videoSlide = $('.video-slide');
    var $videoActive = $('.flex-active-slide');
    var $videoImage = $('.video-image');
    var $videoButton = $('.vidyard-play-btn');
    var $videoModals = $('.video-modal');
    var $videoID = new Vidyard.player("rfhXzPVXopZHr7WAMiRq7k");


    function playVidyardVideo(e) {
        var $videoID = $(e).attr("data-id");
        $(e).parent().addClass('video-open');
        $(e).after('<div class="video-modal"><iframe class="vidyard_iframe" src="//play.vidyard.com/' + $videoID + '.html?v=3.1.1&autoplay=1" width="940" height="528" scrolling="no" frameborder="0" allowtransparency="true" allfullscreen></iframe></div>');
    }

    $videoImage.click(function () {
        playVidyardVideo(this);
    });

    $videoButton.click(function () {
        var videoImage = $(this).parent().find('.video-image');
        playVidyardVideo(videoImage);
    });
});


$('.reports-list-table th').on('mouseover', function () {
    $(this).children('button').children('span').tooltip('show');
});
$('.reports-list-table th').on('mouseout', function () {
    $(this).children('button').children('span').tooltip('hide');
});

$(document).ready(function () {
    $('img[data-video]').on('click', function () {
        var video = '<iframe src="' + $(this).attr('data-video') + '?autoplay=1" width="521" height="293" frameborder="0" allowfullscreen></iframe>';
        $(this).replaceWith(video);
    });

    $('[data-video][data-atdtoggle]').on('click', function () {
        var video = '<iframe src="' + $(this).attr('data-video') + '" frameborder="0" allowfullscreen></iframe>';
        var modalID = $(this).attr('href');
        $(modalID).find('iframe').replaceWith(video);

        // stop video by removing iframe src when data-atdclose is clicked
        $('[data-atdclose]').on('click', function () {
            $(modalID).find('.modal-video iframe').attr('src', '');
        });

        $(document).keyup(function (e) {
            if (e.keyCode === 27) {
                $(modalID).find('.modal-video iframe').attr('src', '');
            }
        });
    });

    $('.super-menu-trigger').each(function () {
        var $trigger = $('<span class="the-trigger" />');
        var $this = $(this);

        $trigger.prependTo($(this));

        $trigger.click(function (e) {
            e.preventDefault();
            e.stopPropagation();
            $this.find('.super-menu').slideToggle(300);
            $trigger.toggleClass('open');
        });
    });

    $('[data-toggle="close"]').on('click', function (e) {
        e.preventDefault();
        var elID = $(this).attr('href');
        $(elID).toggleClass('open');

    });

    $('[data-toggle="delay"]').each(function () {
        var el = $(this);
        var toggleClass = el.data('toggle-class');
        var delay = parseInt(el.data('toggle-delay'), 10);

        window.setTimeout(function () {
            el.toggleClass(toggleClass);
        }, delay);
    });
});

if ($('.tabs-wrapper').length > 0) {
    // New Layout tabs

    // a temp value to cache *what* we're about to show
    var target = null;

    // collect all the tabs
    var tabs = $('.tab').on('click', function () {
        target = $(this.hash).removeAttr('id');

        // if the URL isn't going to change, then hashchange
        // event doesn't fire, so we trigger the update manually
        if (location.hash === this.hash) {
            // but this has to happen after the DOM update has
            // completed, so we wrap it in a setTimeout 0
            setTimeout(update, 0);
        }

        $(this).parents('.tabs').find('a').removeClass('active');
        $(this).addClass('active');
    });

    // get an array of the panel ids (from the anchor hash)
    var targets = tabs.map(function () {
        return this.hash || false;
    }).get();

    // use those ids to get a jQuery collection of panels
    var panels = $(targets.join(',')).each(function () {
        // keep a copy of what the original el.id was
        $(this).data('old-id', this.id);
    });

    function update() {
        if (target) {
            target.attr('id', target.data('old-id'));
            target = null;
        }

        var hash = window.location.hash;
        if (targets.indexOf(hash) !== -1) {
            show(hash);
        }
    }

    function show(id) {
        // if no value was given, let's take the first panel
        if (!id) {
            id = targets[0];
        }
        // remove the selected class from the tabs,
        // and add it back to the one the user selected
        tabs.removeClass('selected').filter(function () {
            return (this.hash === id);
        }).addClass('selected');
        // now hide all the panels, then filter to
        // the one we're interested in, and show it
        panels.hide().filter(id).show();

    }

    $(window).on('hashchange', update);

    // initialise
    if (targets.indexOf(window.location.hash) !== -1) {
        update();
    } else {
        show();
    }


}

var equalheight = function (container) {

    var currentTallest = 0,
        currentRowStart = 0,
        rowDivs = new Array(),
        $el,
        topPosition = 0;
    $(container).each(function () {

        $el = $(this);
        $($el).height('auto')
        topPostion = $el.position().top;

        if (currentRowStart != topPostion) {
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
            rowDivs.length = 0; // empty the array
            currentRowStart = topPostion;
            currentTallest = $el.height();
            rowDivs.push($el);
        } else {
            rowDivs.push($el);
            currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
        }
        for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
            rowDivs[currentDiv].height(currentTallest);
        }
    });
};

if ($(window).width() > 768) {
    $(window).load(function () {
        equalheight('.publication-single');
    });
}

$(window).resize(function () {
    equalheight('.publication-single');
});

$(document).ready(function () {
    if ($('.gty-inview').length) {
        setInterval(function () {
            $('.gty-inview.out').each(function () {
                var partial = $(this).data('partial') == false ? false : true;
                if ($(this).inview(partial)) {
                    $(this).addClass('inview').removeClass('out');
                    if ($(this).find('[data-percent]')) {
                        $(this).find('[data-percent]').each(function() {
                            var percent = parseInt($(this).data('percent'));
                            var current_percent = 1;

                            var count = setInterval(function() {
                                if (Math.ceil(current_percent) < percent) {
                                    current_percent = current_percent + (0.05 * (percent - current_percent));
                                    $(this).text(Math.ceil(current_percent));
                                } else {
                                    clearInterval(count);
                                }
                            }.bind(this), 50);
                        });
                    }
                }
            });

            if ($('.person-container').length) {
                if ($('.person-container').inview(true) && $('.gty-inview-slow.out').length) {
                    var i = 0;
                    var max = $('.gty-inview-slow.out').length;
                    var $images = $('.gty-inview-slow.out');

                    var animation = setInterval(function () {
                        $($images[i]).addClass('inview').removeClass('out');
                        i++;
                        if (i >= max) {
                            clearInterval(animation);
                        }
                    }, 150);
                }
            }
        }, 100);
    }
    var videos = [];

    if ($('[data-vidyard]').length) {
        $('[data-vidyard]').each(function (index) {
            $(this).attr('data-index', index);
            var uuid = $(this).attr('data-vidyard');
            videos[index] = new Vidyard.player(uuid);
        });
    }

    $('body').on('click', '.youtube-grid a, .youtube-btn', function (e) {
        e.preventDefault();

        var target = $($(this).attr('href'));

        $('.toggle-cars').not(target).removeClass('active');
        target.addClass('active');

        $('.youtube-grid a').not($(this)).removeClass('active');
        $(this).addClass('active');

        if ($('[data-youtube]').length) {
            $('[data-youtube]').each(function () {
                $(this).get(0).contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            });

            // target.find('[data-youtube]').get(0).contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
        }

        if ($('[data-vidyard]').length) {
            $('[data-vidyard]').each(function (index) {
                var uuid = $(this).attr('data-vidyard');
                videos[index].pause();
            });

            // var index = target.find('[data-index]').attr('data-index');
            // videos[index].play();
        }

        // $('html, body').animate({
        //     scrollTop: (target.offset().top - 200) + 'px'
        // }, 500);
    });
});

$.fn.inview = function (partial) {
    var $t = $(this),
        $w = $(window),
        viewTop = $w.scrollTop(),
        viewBottom = viewTop + $w.height(),
        _top = $t.offset().top,
        _bottom = _top + $t.height(),
        compareTop = partial === true ? _bottom : _top,
        compareBottom = partial === true ? _top : _bottom;

    return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
};


$(document).ready(function () {
    setInterval(function () {
        if ($(window).scrollTop() > 0) {
            $('body').addClass('page-scrolled');
        } else {
            $('body').removeClass('page-scrolled');
        }
    }, 50);
    
    

    $('.pie').each(function (index, element) {
        var num = +($(this).text());
        var chart = '<svg viewBox="0 0 32 32"><circle class="circle" r="16" cx="16" cy="16" style="stroke-dasharray: 10 100" /></svg>';
        $(this).html(chart);
        $(this).find('.circle').css('stroke-dasharray', num + ' 100');
        $(this).append('<h2>' + num + '%</h2>');
    });

    backToOemSolutions();

    //use the browsers history to go back if the referrer is the the same as the current host, otherwise add a link to all ad solutions
    function backToOemSolutions() {
        var currentHost = window.location.hostname,
            $_backButton = $('.oem-solutions-back-button'),
            referringPage = document.referrer;
        if ($_backButton.length) {
            if (referringPage.includes(currentHost) && referringPage.includes('ad-solution')) {
                $_backButton.html('< Back');
                $_backButton.on('click', function (event) {
                    event.preventDefault();
                    history.back();
                });
            } else {
                $_backButton.html('< OEM Ad Solutions');
                $_backButton.attr('href', '/oem/oem-solutions/');
            }
        }
    }

});

$(function () {
    if (location.pathname.split("/")[3]) {
        //$('.oem-page-nav a[href^="/oem/' + location.pathname.split("/")[2] + '/' + location.pathname.split("/")[3] + '"]').addClass('active');
        $('.band-okta a[href^="/oem/' + location.pathname.split("/")[2] + '/' + location.pathname.split("/")[3] + '"]').addClass('active');
    } else {
        if (location.pathname.split("/")[2] && location.pathname.split("/")[2] != "advertising-opportunities") {
            //$('.oem-page-nav a[href^="/oem/' + location.pathname.split("/")[2] + '"]').addClass('active');
            $('.band-okta a[href^="/oem/' + location.pathname.split("/")[2] + '"]').addClass('active');
        }
    }

    $('.oem-page-nav a[href^="/oem-ad-solution-tiers/' + location.pathname.split("/")[2] + '"]').addClass('active');
});


// var printBtn = document.querySelector('.print-btn');

// printBtn.addEventListener("click", printWindow);

// function printWindow(){
//     window.print();
// }

$('.autoplay').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
});
// }

$(function() {
  
  var html = $('html');
  // Detections
  if (!("ontouchstart" in window)) {
    html.addClass("noTouch");
  }
  if ("ontouchstart" in window) {
    html.addClass("isTouch");
  }
  if ("ontouchstart" in window) {
    html.addClass("isTouch");
  }
  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    if (navigator.appVersion.indexOf("Trident") === -1) {
      html.addClass("isEDGE");
    } else {
      html.addClass("isIE isIE11");
    }
  }
  if (navigator.appVersion.indexOf("MSIE") !== -1) {
    html.addClass("isIE");
  }
  if (
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1
  ) {
    html.addClass("isSafari");
  }

  // On Screen

  $.fn.isOnScreen = function() {
    var elementTop = $(this).offset().top,
      elementBottom = elementTop + $(this).outerHeight(),
      viewportTop = $(window).scrollTop(),
      viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  function detection() {
    for (var i = 0; i < items.length; i++) {
      var el = $(items[i]);

      if (el.isOnScreen()) {
        el.addClass("in-view");
      } else {
        el.removeClass("in-view");
      }
    }
  }

  var items = document.querySelectorAll(
    "*[data-animate-in], *[data-detect-viewport]"
  ),
    waiting = false,
    w = $(window);

  w.on("resize scroll", function() {
    if (waiting) {
      return;
    }
    waiting = true;
    detection();

    setTimeout(function() {
      waiting = false;
    }, 100);
  });

  $(document).ready(function() {
    setTimeout(function() {
      detection();
    }, 500);

    for (var i = 0; i < items.length; i++) {
      var d = 0,
        el = $(items[i]);
      if (items[i].getAttribute("data-animate-in-delay")) {
        d = items[i].getAttribute("data-animate-in-delay") / 1000 + "s";
      } else {
        d = 0;
      }
      el.css("transition-delay", d);
    }
  });
});

jQuery('.alert-banner-close').on('click', function() {
  jQuery(this).parent().remove();
  jQuery('html').removeClass('has-alert-banner');
  setCookie('viewed_alert', 1, 7);
});
function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

/* Carousel */

//slideshow style interval
var autoSwap = setInterval( swap,7000);

//pause slideshow and reinstantiate on mouseout
$('.carousel, .slider').hover(
  function () {
    clearInterval(autoSwap);
}, 
  function () {
   autoSwap = setInterval( swap,7000);
});

//global variables
var items = [];
var startItem = 1;
var position = 0;
var itemCount = $('.carousel>li').length;
var leftpos = itemCount;
var resetCount = itemCount;

//unused: gather text inside items class
$('.carousel>li').each(function(index) {
    items[index] = $(this).text();
});

//swap images function
function swap(action) {
  var direction = action;
  
  //moving carousel backwards
  if(direction == 'counter-clockwise') {
    var leftitem = $('.left-pos').attr('id') - 1;
    if(leftitem == 0) {
      leftitem = itemCount;
    }
    
    $('.right-pos').removeClass('right-pos').addClass('back-pos');
    $('.main-pos').removeClass('main-pos').addClass('right-pos');
    $('.left-pos').removeClass('left-pos').addClass('main-pos');
    $('#'+leftitem+'').removeClass('back-pos').addClass('left-pos');
    
    startItem--;
    if(startItem < 1) {
      startItem = itemCount;
    }

    $('[data-jump-to]').removeClass('active');
    $('[data-jump-to="' + startItem + '"]').addClass('active');
  }
  
  //moving carousel forward
  if(direction == 'clockwise' || direction == '' || direction == null ) {
    function pos(positionvalue) {

      if(positionvalue != 'leftposition') {
        //increment image list id
        position++;
        
        //if final result is greater than image count, reset position.
        if((startItem+position) > resetCount) {
          position = 1-startItem;
        }
      }
    
      //setting the left positioned item
      if(positionvalue == 'leftposition') {
        //left positioned image should always be one left than main positioned image.
        position = startItem - 1;
      
        //reset last image in list to left position if first image is in main position
        if(position < 1) {
          position = itemCount;
        }
      }
   
      return position;
    }  
  
   $('#'+ startItem +'').removeClass('main-pos').addClass('left-pos');
   $('#'+ (startItem+pos()) +'').removeClass('right-pos').addClass('main-pos');
   $('#'+ (startItem+pos()) +'').removeClass('back-pos').addClass('right-pos');
   $('#'+ pos('leftposition') +'').removeClass('left-pos').addClass('back-pos');

    startItem++;
    position=0;
    if(startItem > itemCount) {
      startItem = 1;
    }

    $('[data-jump-to]').removeClass('active');
    $('[data-jump-to="' + startItem + '"]').addClass('active');
  }
}

//next button click function
$('#next').click(function() {
  swap('clockwise');
});

//prev button click function
$('#prev').click(function() {
  swap('counter-clockwise');
});

//if any visible items are clicked
$('.items').click(function() {
  if($(this).attr('class') == 'items left-pos') {
     swap('counter-clockwise'); 
  }
  else {
    swap('clockwise'); 
  }
});

function jumpTo(jump_to) {
    if(!$('#' + jump_to)) {
        return;
    }

    position = 0;
    startItem = jump_to;

    var left = jump_to - 1;
    left = left >= 1 ? left : itemCount;
    var right = jump_to + 1;
    right = right <= itemCount ? right : 1;


    for(var i = 1; i <= itemCount; i++) {
        $('#'+ i).removeClass('main-pos').removeClass('left-pos').removeClass('right-pos').addClass('back-pos');
    }

    $('#'+ jump_to).removeClass('back-pos').addClass('main-pos');
    $('#'+ right).removeClass('back-pos').addClass('right-pos');
    $('#'+ left).removeClass('back-pos').addClass('left-pos');

    $('[data-jump-to]').removeClass('active');
    $('[data-jump-to="' + jump_to + '"]').addClass('active');
}

$('[data-jump-to]').click(function(e) {
    e && e.preventDefault();

    var jump_to = parseInt($(this).data('jump-to'));
    jumpTo(jump_to);
});

var toggle_elements = document.querySelectorAll('[data-toggle-element]');
toggle_elements.forEach(function(toggle_element) {
  var toggle_identifier = toggle_element.dataset.toggleElement;
  var toggle_class = toggle_element.dataset.toggleClass || 'active';
  var togglable_elements = document.querySelectorAll('[data-togglable-element="' + toggle_identifier + '"]');
  toggle_element.addEventListener('click', function(e) {
    e.preventDefault();
  console.log(togglable_elements);
    togglable_elements.forEach(function(togglable_element) {
      togglable_element.classList.toggle(toggle_class);
    });
  });
});

var wrapper = $('.footer-nav'),
    boxes = wrapper.children(),
    boxSize = boxes.first().outerWidth(true);

$(window).resize(function() {
    var w = wrapper.width(),
        breakat = Math.floor(w / boxSize); // this calculates how many items fit in a row

    last_per_row = boxes.filter(':nth-child(' + breakat + 'n)') // target the last element of each row
    // do what you want with the last_per_row elements..
});

/*
* function wrapper for guidelines page display logic
* includes functionality for guidelines page anchor links
**/
guidelinesPage();

function guidelinesPage() {
    //use the #guidelines id as a key for detecting the page
    if ($('#guidelines').length) {
        //console.log('Guidelines page detected');
        var guidelineHash = location.hash,
            $_hashLink = $('.accordion-toggle[href="' + guidelineHash + '"]');
        triggerAccordionToggleClick($_hashLink);
        //console.log('guidelineHash', guidelineHash);
        //console.log('$_hashLink', $_hashLink);
        function triggerAccordionToggleClick($_theLinkElement) {
            //If there is an anchor link to the section, trigger a click(because the content is hidden by default)
            //console.log($_theLinkElement)
            if ($_theLinkElement.length) {
                try {
                    console.log('hashlink detected, link triggered');
                    setTimeout(function () {
                            $_theLinkElement.trigger('click');
                            $('html').animate({scrollTop: $($_theLinkElement).offset().top - 100}, 1000);
                        },
                        1000);
                }
                catch (err) {
                    console.warn(err);
                }
            }
            else {
                //Do nothing
                //console.log('hashlink was NOT detected');
            }
        }

        //Functionality for the table of contents links
        var $_toc_links = $('.guidelines-toc__link');
        $_toc_links.on('click', function (event) {
            event.preventDefault();
            var theDestinationID = $(this).attr('href');
            location.hash = theDestinationID;
            $_hashLink = $('.accordion-toggle[href="' + theDestinationID + '"]');
            triggerAccordionToggleClick($_hashLink);
        });
    }
}

//tabs from autotrader

$('.ae-tabs a').click(function (e) {
    e.preventDefault();
    var target = $($(this).attr('href'));

    $('.ae-tabs li').removeClass('active');
    $(this).parent().addClass('active');

    $('.ae-tab').not(target).removeClass('active');
    target.addClass('active');
});